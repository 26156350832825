import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NikkeGuidesZwei: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Zwei guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_zwei.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Zwei Guide & Review</h1>
          <h2>A guide & review for Zwei in NIKKE: Goddess of Victory.</h2>
          <p>
            Last updated: <strong>09/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Zwei" />
              <div className="employee-container for-nikke">
                <NikkeCharacter mode="icon" slug="zwei" enablePopover />
              </div>
              <p>
                A closure to the studious trio, Zwei from Elysion embarks on her
                military journey as a Supporter that can continuously repair the
                team's cover HP and provide Pierce Damage buffs. She can also
                transform her weapon into a one-shot rifle that fires a
                compressed laser in a straight line, penetrating enemies.
                Besides her special weapon, her signature dual shotguns are one
                of a kind, but will that translate into greater power?
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Zwei is a highly situational unit with no solid foothold. Design
                wise, her kit's foundation is to buff pierce damage dealers,
                especially nukes such as Snow White's or Maxwell's. From our
                perspective, she is intentionally made to be a filler unit as
                her performance seems to lag behind most if not all meta units.
                Or, if she does somehow become performant, she will be far from
                versatile and unprofitable for most players. Despite this, she
                may have some uses for endgame players, but do note that she is
                extremely Raid-focused with some hints of presence in Elysion
                Tower. Her objective is strictly for damage min-maxxing.
              </p>
              <p>
                Objectively, her cover repair is nice, which can be considered a
                Healing Effect for the team, so ideally you should not need to
                run healers anymore (assuming your cover can sustain). This can
                be helpful for clearing Elysion Tower when Cover HP is the sole
                formula for success. However, why use Zwei when Marciana's
                healing is more reliable and much easier to use for the vast
                majority of stages? We also have Diesel (Treasure), who is
                already a superb alternative. Sure, Zwei pairs better with
                Guillotine, but at the same time investing in Guillotine for
                just Tower is impractical.
              </p>
              <p>
                Now, as for her Pierce Damage, it is huge, but it is only
                effective for one shot and whatever is after that will only be
                subject to half of its maximum strength. Snow White and Maxwell
                are pretty much the only two units that can capitalize on this,
                and for others, there are usually better options (like D:Wife,
                Liter, FrimaT for Laplace etc).
              </p>
              <p>
                All things considered, Zwei's immediate impact is weak, and she
                will likely only fancy collectors.
              </p>
              <h5>Should I pull this unit?</h5>
              <p>
                Only pull one copy if you are a competitive Solo Raid player,
                and that too only if you fancy using Snow White teams.
                Otherwise, to put it simply, no. Not worth spending hard-earned
                resources on an expendable unit who dilutes what's already an
                underperforming Elysion pool into a more disappointing
                contingent. Ideally, if you do not care about Solo Raids that
                much, wishlist her and save for the upcoming Evangelion collab.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <StaticImage
                src="../../../images/nikke/reviews/zwei_guide_1.webp"
                alt="Kit"
              />
              <p>
                Zwei plays a supportive role and lacks notable offense
                capabilities. Her skillset revolves around Pierce Damage and
                just Pierce Damage, literally! In total, she can grant up to{' '}
                <strong>~45.7% Pierce Damage</strong> for your team, which is
                substantial, but its niche usability relegates her into a
                secondary role. She also helps with survivability but there is
                some catch.
              </p>
              <Alert variant="primary">
                <p>
                  As a reminder, Pierce Damage belongs to the same group as
                  Attack Damage & Parts Damage, so you need to beware of
                  potential dilutions.
                </p>
              </Alert>
              <h5>Heaven of Penetration</h5>
              <p>Zwei's S1 consists of two components.</p>
              <ul>
                <li>
                  The first component grants modest Pierce Damage boost to the
                  next shot fired by every unit in your team. This favors SW and
                  Maxwell as all their Burst Damage is contained within that one
                  shot, not so much for others and can even be deemed trivial.
                </li>
                <li>
                  The second component grants continuous Pierce Damage for 10s
                  to everyone in the team upon entering Full Burst. This
                  synergizes well with Red Hood, Alice, Laplace, and literally
                  any units with Pierce capabilities.
                </li>
              </ul>
              <p>
                Although Zwei pairs best with SW and Maxwell, she can still be
                paired with these other Piercers. You are only missing ~20%
                Pierce Damage, and her ~25% is still greater than D:Wife's
                ~18.61%. But, please do note that she does not offer CDR nor ATK
                buff, which we believe is a tangible downgrade from D: Wife's
                complete arsenal. Her usage may also lead to "slot pressure".
              </p>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> What is Slot
                  Pressure?
                </h6>
                <p>
                  Using Zwei almost always necessitates the inclusion of B2
                  CDRs, creating what pros call "slot pressure", where a slot is
                  occupied by a less impactful unit and thus wasted. This is
                  because B2s generally have weaker kits compared to B1s (like
                  Dolla having borderline zero impact and SHelm being effective
                  against Electric bosses only).{' '}
                </p>
              </blockquote>

              <h5>Gacha Inside Gacha!</h5>
              <p>
                Zwei also provides Cover HP Recovery, which is useful when Cover
                HP is more effective than regular healing (such as against
                Nihilister & Ultra). However, in other situations, it functions
                similarly to regular healing that does not interfere with HP
                mechanics (like Guillotine) with reduced reliability due to
                potential breaches and mandatory "take covers" that can lead to
                DPS loss. They are inefficient against bosses that pierce covers
                or deal repetitive attacks in a short window (your units in
                cover for too long = DPS loss). Besides that, destroyed covers
                cannot be healed, so if they were breached, Zwei would lose her
                regenerative powers.
              </p>
              <Alert variant="primary">
                <p>
                  In general, cover healing is worse for everything but bossing.
                  This is due to the fact that, at High Deficit, wall stages
                  contain farters or raptures that would shatter your cover
                  instantly. Moreover, bosses with Burst Damage like Modernia
                  would render cover healing useless.
                </p>
              </Alert>
              <p>
                Since Zwei's basic attack is classified as only one instance of
                attack (despite her being Dual SG), her Cover HP Recovery should
                theoretically trigger every ~4.08s on average without any
                buff/debuff, but Cube, Max Ammo, Reload Speed, and Attack Speed
                will all shorten the interval. Note that this number has already
                incorporated her base reload time.
              </p>
              <Alert variant="primary">
                <p>
                  The Since it is also a basic Cover HP Recovery effect, it can
                  also prompt Tia's CDR passive ability, so theoretically they
                  can be used with together, but whether splitting Tia & Naga is
                  optimal for Raids is yet to be researched. The idea is that
                  you pair Crown + Naga and Tia + Zwei for better DPS funneling.
                </p>
              </Alert>
              <p>
                All of these help Ein Zwei also increases the team's Critical
                Rate, but it is negligible except on units that already have
                lots of Critical Damage; wield weapons that have poor accuracy
                (SGs, SMGs); or have Skill Damage a major portion of their DPS
                (Summer Sakura). Why so? It is because the formula dictates that
                CRIT is more valuable against bosses without core or outside
                optimal range as these dilute multipliers.
              </p>
              <p>
                But, Zwei's CRIT Rate only lasts 5 seconds! It barely matters
                for anything but intermittent attacks with concentrated
                firepower. Take Snow White's Burst Skill, for example. When
                playing her, you can technically fish for CRIT, making CRIT Rate
                more of an RNG attenuation tool than a DPS boosting tool. In
                other words, you still need to "gacha" for critical hits.
                Furthermore, you still have to land SW's Burst Skill within 5s
                of activating Zwei's Burst Skill (note that the cycle from B1 ➔
                B3 takes time!).
              </p>
              <Alert variant="primary">
                <p>
                  For tryhards who spend hours restarting Raid runs for maximum
                  damage, this extra Critical Rate is more of a QoL improvement
                  more than anything, with the only problem being the buff lasts
                  for 5 seconds only. Compared that to Miranda, who technically
                  gives more Critical Damage (thus higher damage ceiling) but
                  without Critical Rate (harder RNG). Do you think the improved
                  RNG a fair tradeoff for lower damage ceiling?
                </p>
              </Alert>
              <h5>First Summer Nostalgia</h5>
              <p>
                Our initial impression is simply "what". Her special charged
                weapon lowers her DPS. Sure, it helps reload her magazine, but
                why? Is that one piercing SR ammo with barely any damage going
                to do anything? It is like having a flashback to Nikke's first
                summer when a particular Water-element DPS unit was released.
                She was supposed to melt Fire bosses but, even after buffs, she
                was no better than eye candy.
              </p>
              <p>
                Well, at least Zwei's Burst Skill still provides a meaty Pierce
                Damage boost, which actually rocks depending on whom you match
                her with. It lasts 10 seconds too, which is plenty for the
                duration of common Burst Skills.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Usage Analysis and Tier List" />
              <h5>
                Campaign (LD) - <strong className="a">A</strong> | Campaign (HD)
                - <strong className="b">B</strong>
              </h5>
              <p>
                {' '}
                Zwei buffs piercers, which makes her a niche buffer. However, in
                Campaign, you only need one team. That one team usually has 2 B3
                DPS slots which are used up by pierce units most of the time
                anyways, such as Red Hood, Alice, Maxwell, and sometimes even
                Snow White! Hence, we believe Zwei is an okay unit to use
                alongside the campaign meta units, but she is NOT better than
                the current existing meta units such as D: Killer Wife, Liter,
                Dorothy etc. This is because Zwei lacks some key things a B1
                should have in Campaign, with the main abilities being ATK buffs
                and CDR. In High Deficit Campaign, this become extremely
                apparent as Zwei falls off in usability by a decent margin.
              </p>
              <h5>
                Boss (Solo) - <strong className="a">A</strong> | Boss (Adds) -{' '}
                <strong className="b">B</strong>
              </h5>
              <p>
                {' '}
                Against Bosses, we will be focusing on Raids. In Union Raids,
                Zwei does not have a spot. However, in Solo Raids, Zwei has a
                strong case to be used in the Snow White - Yulha team against
                Solo Bosses over Miranda for the average player who do not like
                to mald for crits. Miranda's crit buffs carry her hard as they
                substantially increase Snow White's damage, provided you press
                the reset button enough times. However, if you do not crit your
                shots, SW's nuke with Zwei hits harder! Another reason to use
                Zwei is possibly as a pair of Tia, when Naga is stolen by Crown.
                This gives both Tia's Attack Damage buffs and Zwei's Pierce
                Damage Buff to the team, allowing for hard hitting nukes by
                SnowWhite. One final use-case for Zwei over Miranda is when
                Maxwell is used over Yulha as a pair of SW, which was seen in
                the StormBringer Solo Raid. All of this can add towards pulling
                one copy of Zwei for competitive players who want to try and do
                better in Solo Raids! Against Bosses with Adds, SW is less
                usable, and hence, Zwei herself is less usable in the meta.
              </p>
              <h5>
                PVP - <strong className="b">B</strong>
              </h5>
              <p>
                {' '}
                Dual Wielding Shotguns!? Initially sounded juicy burst gen for
                PvP, right? Unfortunately that is not the case. Zwei wields Dual
                SG, each ejecting 5 pellets every time she shoots. The Burst
                Generation is 0.35% for each pellet, so she generates 3.5%
                energy for each shot as a whole. This places her on similar
                levels as Nihilister in 2RL, and at xMica level in other speeds
                in PvP. To compare, ordinary clip SGs generate 4.5% energy per
                shot.
              </p>
              <p>
                Her normal attacks still only hit 10 pellets in total, 5 each
                from both guns. Hence, her Burst gen is on average below most
                units, and is more or less comparable to xMica. While xMica
                provides a decent ATK buff for the whole team, Zwei provides
                pierce damage buffs, which, while Alice and Red Hood appreciate
                them, are not much of a benefit to them since: firstly, these 2
                units already do enough damage without buffs to kill opponent
                teams, and secondly, they have their own problems that they deal
                with. Hence, the decision we have come to is that Zwei is
                unneeded in the current meta in PvP, but is usable as a mid tier
                unit for when you lack good PvP units.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>There is no need to OL Zwei's gear.</p>
              <ul>
                <li>
                  <strong>Essential:</strong> None
                </li>
                <li>
                  <strong>Ideal:</strong> None
                </li>
                <li>
                  <strong>Passable:</strong> None
                </li>
                <li>
                  <strong>Priority:</strong> None
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <ul>
                <li>
                  <strong>Skill 1: 4/7/10</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    Half of her buffs lie in this skill. You can leave this
                    skill at 7, but eventually max out this Skill if she stays
                    relevant for your account.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 4/7</strong>
                </li>
                <ul>
                  <ul>
                    <li>
                      {' '}
                      This skill does not have offensive buffs other than crit
                      rate. Hence, it is expensive to upgrade this skill. We
                      recommend upgrading it to 4. At max, upgrade it to 7.
                    </li>
                  </ul>
                </ul>
                <li>
                  <strong>Burst Skill: 4/7/10</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    The remaining pierce damage buffs are in Zwei's Burst Skill.
                    Hence, we also recommend upgrading this to 10 ideally, but
                    you can definitely leave it at 7.
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="zwei" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <p>
                Any cube works on Zwei. Zwei is not a good damage dealer
                herself, but to maximise the number of bullets shot, the best
                cubes are either Bastion or Resilience Cubes.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: Snow White goes to school</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="zwei" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="helm" enablePopover />
                    <NikkeCharacter
                      mode="icon-tier"
                      slug="diesel"
                      enablePopover
                      tierID={25}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="snow-white"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="yulha" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                {' '}
                Bossing team. Direct subsitute for Miranda. Weaker to Miranda if
                the player does fully optimised crit runs. Upgrade to Miranda in
                average runs where players do not mald for crit.
              </p>
              <h5>Team #2: Yulha leaves for work</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="zwei" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="snow-white"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                {' '}
                Bossing team again. Miranda cannot buff 2 units, a severe
                drawback that Zwei does not have. Whenever there is a
                possibility of Yulha being hard to use, which was something we
                saw in StormBringer Solo Raid, Zwei is the better option of the
                two!
              </p>
              <h5>Team #3: Zwei comforts Tia in Naga's absence</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="tia" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="zwei" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="snow-white"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="yulha" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                {' '}
                Bossing team yet again. Since Naga can possible leave for the
                Crown team sometimes, Tia and Zwei are a good combination due to
                the fact that Zwei has insane cover heals! These cover heals not
                only proc Tia's CDR, they also help her to survive the possible
                onslaught from the boss!
              </p>
              <h5>Team #4: General Team Comp</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="zwei" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                {' '}
                General Team setup, where the B3s need to be Pierce Damage
                dealers for Zwei to be effective.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Substantial Pierce Damage Buff, especially for Snow
                          White and Maxwell.
                        </li>
                        <li>
                          Her personal ATK and True Damage buffs are massive,
                          and the former has high uptime.
                        </li>
                        <li>
                          Has decent cover heal, assisting in survivability and
                          allowing her to be a pair of Tia.
                        </li>
                        <li>Decent crit buff, albeit for only 5 seconds.</li>
                        <li>Her reload animation is super satisfying.</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Her buffs are niche for piercers only.</li>
                        <li>
                          The duration of crit rate is short, which forces you
                          to build Charge Speed on Snow White.
                        </li>
                        <li>
                          ATK is slightly diluted for Ein.Being an Elysion
                          banner means you are probably not a meta unit, which
                          is true in this case as well.
                        </li>
                        <li>
                          Ingrid please make something good for Elysion
                          Tower.... :(
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesZwei;

export const Head: React.FC = () => (
  <Seo
    title="Zwei Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Zwei in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
